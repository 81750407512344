<template>
  <div id="card" class="container">
    <div class="d-flex">
      <h4 class="card-title mr-auto m-0">{{ name }}</h4>
      <span class="arrow-down-icon" :class="{ arrowup: isToggled }" @click="toggle()">
        <img src="../assets/svgs/arrow-up.svg" />
      </span>
    </div>
    <!-- / card Header -->
    <div id="add-request" v-if="!isToggled && level != 'headquarters'">
      <b-button variant="btn btn-primary" @click="addCalendar()">{{
        i18n['prayer-calendar'].tcAddPrayerCalendar
}}</b-button>
    </div>
    <div class="card-table" v-if="!isToggled">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ i18n['prayer-calendar'].tcType }}<b-icon-arrow-down scale="1.2" shift-h="3"></b-icon-arrow-down>
              </th>
              <th scope="col">
                {{ i18n['prayer-calendar'].tcCalendar }}<b-icon-arrow-down scale="1.2" shift-h="3"></b-icon-arrow-down>
              </th>
              <th>{{ i18n['prayer-calendar'].tcView }}</th>
              <th scope="col">{{ i18n['prayer-calendar'].tcEditDelete }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="card-row" v-for="(item, index) in itemValues" :key="index + 'item'">
            <tr>
              <th scope="row">{{ translateType(item.type) }}</th>
              <td>{{ item.name }}</td>
              <td>
                <a href="#" @click.prevent="openCalendar(item)">{{ i18n['prayer-calendar'].tcView }}</a>
              </td>
              <td>
                <a href="#" @click.prevent="edit(item)" v-if="showEdit(item)">
                  <b-icon-pencil-square color="#32C5FF" scale="1.2"></b-icon-pencil-square>
                </a>
                <a href="#" class="delete-icon" @click.prevent="deleteCalendar(item)" v-if="showDelete(item)">
                  <b-icon-x variant="danger" scale="2"></b-icon-x>
                </a>
              </td>
              <td>
                <b-form-checkbox v-if="showActiveSwitches(item.category)" v-model="item.active" name="check-button"
                  switch @change="toggleActive(item)">
                  {{ item.active ? i18n['prayer-calendar'].tcActive : i18n['prayer-calendar'].tcInactive }}
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- / card content table -->
    <div class="bottom-buttons" v-if="!isToggled">
      <b-button variant="btn btn-light" @click="toggleCalendarStatus()" v-if="showInactiveCalendar">{{
        i18n['prayer-calendar'].tcViewAllCalendars
        }}</b-button>
      <b-button variant="btn btn-light" @click="openManagePrayerRequest()">{{
        i18n['prayer-calendar'].tcManagePrayerRequests
        }}</b-button>
    </div>
    <!-- / card bottom buttons -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PrayerCalendar',
  props: {
    items: { type: Array },
    level: { type: String },
    name: { type: String },
    i18n: {
      type: Object,
      default: () => {
        return {
          'prayer-calendar': {
            tcActive: 'Active',
            tcAddPrayerCalendar: 'Add Prayer Calendar',
            tcCalendar: 'Calendar',
            tcCancel: 'Cancel',
            tcEditDelete: 'Edit/Delete',
            tcInactive: 'Inactive',
            tcManagePrayerRequests: 'Manage Prayer Requests',
            tcOk: 'Ok',
            tcType: 'Type',
            tcView: 'View',
            tcViewAllCalendars: 'View All Calendars',
          },
        }
      },
    },
  },
  data() {
    return {
      isToggled: true,
      showInactiveCalendar: false,
      fields: ['Request', 'Type', 'Active', 'Edit/Delete'],
      i18nOriginal: JSON.parse(JSON.stringify(this.i18n)),
    }
  },
  methods: {
    ...mapActions({
      setCalendarItem: 'prayerCenter/setCalendarItem',
      setPrayerItemAndLevel: 'prayerCenter/setPrayerItemAndLevel',
      updateCalendar: 'prayerCenter/updateCalendar',
    }),
    async toggleActive(data) {
      try {
        let params = {
          key: data.key,
          name: data.name,
          active: data.active,
          user: this.userId,
          year: null,
        }
        let response = await this.updateCalendar(params)
        if (!response) {
          throw 'Error: Failed to update the calendar status.'
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          text: e,
          confirmButtonText: this.i18nOriginal['prayer-calendar'].tcOk,
        }).then(() => {
          this.items.find((it) => it.key === data.key).active = !data.active
        })
      }
    },
    showDelete(item) {
      if (
        this.level == 'camp' &&
        (item.category.toLowerCase() === 'churches' || item.category.toLowerCase() === 'members')
      ) {
        return false
      } else if (
        (this.level === 'state' || this.level === 'country') &&
        (item.category.toLowerCase() === 'widows' || item.category.toLowerCase() === 'officers')
      ) {
        return false
      } else if (this.level === 'headquarters') {
        return false
      }
      return true
    },
    showActiveSwitches(category) {
      return !['International', 'Convention'].includes(category)
    },
    showEdit(item) {
      return this.level !== 'headquarters'
    },
    async edit(item) {
      await this.setPrayerItemAndLevel({ item: item, level: this.level })
      this.$router.push({ name: 'prayer-edit-calendar' })
    },
    async addCalendar() {
      await this.setPrayerItemAndLevel({ item: {}, level: this.level })
      this.$router.push({ name: 'prayer-edit-calendar' })
    },
    toggleCalendarStatus() {
      this.showInactiveCalendar = !this.showInactiveCalendar
    },
    async openCalendar(obj) {
      obj.level = this.level
      await this.setCalendarItem(obj)
      await this.setPrayerItemAndLevel({ item: {}, level: this.level })
      this.$router.push({ name: 'prayer-manage-calendar' })
    },
    openManagePrayerRequest() {
      this.$router.push({
        name: 'prayer-add-edit-request',
      })
    },
    async deleteCalendar(item) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: `${item.name} ${this.i18n['prayer-calendar'].tcWillBeDeleted}`,
        confirmButtonText: this.i18nOriginal['prayer-calendar'].tcOk,
        cancelButtonText: this.i18nOriginal['prayer-calendar'].tcCancel,
      }).then((result) => {
        if (result.value) {
          item.level = this.level
          this.$emit('delete_approved', item)
        }
      })
    },
    toggle() {
      this.isToggled = !this.isToggled
    },
    translateType(type) {
      const prayerRequestType = `tc${type}`
      const typeText = this.i18n['prayer-calendar'].hasOwnProperty(prayerRequestType)
        ? this.i18n['prayer-calendar'][prayerRequestType]
        : type
      return typeText
    },
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
    itemValues() {
      let values = this.items
      if (this.showInactiveCalendar) {
        return values.filter((value) => value.active === false)
      }
      return values
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/PrayerCalendar.scss';
.third-field {
  padding-right: 20px !important;
  padding-bottom: 30px !important;
}
.delete-icon {
  margin-left: 15px;
}
.container{
  padding-bottom: 0px;
}
</style>
